/* FUENTES */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
@font-face {
  font-family: 'chalkboardregular';
    src: url('fonts/chalkboard-webfont.woff2') format('woff2'),
         url('fonts/chalkboard-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* ANIMACIONES */
@keyframes fondo_colores {
  0% { background-position: -70px -400px; opacity: 0; }
  100% { background-position: 70px -100px; opacity: 1;}
}

@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1;}
}

/* ESTILOS GENERALES */
html, body, #root {
  height:100%;
  font-family: 'chalkboardregular', sans-serif;
  background-color: #f9eee4;
}

.fondo_principal{
  background-image: url('img/up_main_bg.svg');
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: #fff;
  animation: fade 500ms ;
  animation-timing-function: ease-in-out;
}

.logo_font{
  font-size: 4em;
  font-weight: bold;
  padding-top: 60px;
  padding-bottom: 60px;
}

.logo{
  display: block;
  width:100%;
  max-width: 150px;
  margin: 0 auto;
}

.mini_logo{
  display: block;
  width:100%;
  max-width: 80px;
  margin: 0 auto;
}

.login_select{
  padding-top: 150px;
}

.login_select a{
  padding: 10px;
  color: #fff;
}

.login_select a:first-child{
  border-right: solid 2px #fff;
}

.form-inicio{
  background-color: transparent;
  color: #fff;
  border: none;
  border-bottom: solid 2px #fff;
}

.menu-contenedor{
  width: 100%;
  position: absolute;
  top: 0;
  background-color: transparent;
  height: 50px;
  padding: 10px;
  text-align: right;
}

.menu-contenedor .mini_logo{
  max-width: 40px;
}

.fondo_blanco{
  background-color: #f9eee4;
  padding-top: 25%;
  animation: fade 500ms ;
  animation-timing-function: ease-in-out;
  color: #604b3d;
}

.fondo_espirituales{
  background-image: url('img/up-fondo-espirituales.svg');
  background-position: 60px -70px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 10%;
  animation: fondo_colores 500ms ;
  animation-timing-function: ease-in-out;
}

.fondo_fisicos{
  background-image: url('img/up-fondo-fisicos.svg');
  background-position: 60px -70px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 10%;
  animation: fondo_colores 500ms ;
  animation-timing-function: ease-in-out;
}

.fondo_intelectuales{
  background-image: url('img/up-fondo-intelectuales.svg');
  background-position: 60px -70px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 10%;
  animation: fondo_colores 500ms ;
  animation-timing-function: ease-in-out;
}

.fondo_relacionales{
  background-image: url('img/up-fondo-relacionales.svg');
  background-position: 60px -70px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 10%;
  animation: fondo_colores 500ms ;
  animation-timing-function: ease-in-out;
}

.fondo_emocionales{
  background-image: url('img/up-fondo-emocionales.svg');
  background-position: 60px -70px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 10%;
  animation: fondo_colores 500ms ;
  animation-timing-function: ease-in-out;
}



.icono_seccion{
  width: 100%;
  max-width: 200px;
  display: block;
  margin-left: auto;
}
.titulo_seccion{
  font-weight: bold;
  color: #6d5244;
}

.descripcion_seccion{
  font-size: 1.2em;
  color: #604b3d;
}
.contenedor_grafico{
  min-height: 300px;
  background-color: transparent;
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Estilos de mi perfil */
.contenedor_perfil{
  text-align: center;
}

.foto_perfil{
  width: 200px;
  height: 200px;
  background-color: #fff;
  margin: 0 auto 50px;
  border-radius: 50%;
}
.foto_perfil_sm{
  width: 100px;
  height: 100px;
  background-color: #fff;
  margin: 0 auto 50px;
  border-radius: 50%;
}

/* Estilos del menu */
.cuerpo_menu{
  text-align: center;
}
.cuerpo_menu h3{
  font-size: 36px;
}

.cuerpo_menu a{
  display: block;
}

.medalla_menu{
  width: 60px;
}

.medalla_perfil{
  width: 100px;
}

.boton_menu_sencillo{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}



.boton_menu_espiritual{
  background-color: #6A7EFF;
  color:#000000;
}

.boton_menu_espiritual .medalla_menu{
  filter: brightness(0%);
}
.boton_menu_fisico{
  background-color: #3EE1E0;
  color:#000000;
}
.boton_menu_fisico .medalla_menu{
  filter: brightness(0%);
}
.boton_menu_intelectual{
  background-color: #f6d554;
  color:#000000;
}
.boton_menu_intelectual .medalla_menu{
  filter: brightness(0%);
}
.boton_menu_relacional{
  background-color: #f49b4c;
  color:#000000;
}
.boton_menu_relacional .medalla_menu{
  filter: brightness(0%);
}
.boton_menu_emocional{
  background-color: #fe6656;
  color:#000000;
}
.boton_menu_emocional .medalla_menu{
  filter: brightness(0%);
}
/* Estilos de selector */

.medalla_selector{
  background-color: #6a7eff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
  padding: 5px;
}

.etiqueta_selector{
  text-align: center;
  font-size: 1.3em;
  padding-top: 10px;
}

.porcentaje_selector{
  font-size: 1.3em;
  font-weight: bold;
  margin-left:5px;
  padding-top: 10px;
}

.progress{
  height: 0.5rem;
}
.progress_espiritual .progress-bar{
  background-color: #6A7EFF;
}
.progress_fisico .progress-bar{
  background-color: #3EE1E0;
}

.progress_intelectual .progress-bar{
  background-color: #f6d554;
}

.progress_intelectual .progress-bar{
  background-color: #f6d554;
}

.progress_relacional .progress-bar{
  background-color: #f49b4c;
}
.progress_emocional .progress-bar{
  background-color: #fe6656;
}

/*Estilos del calendario */
.fc .fc-list-sticky .fc-list-day > *{
  background: #604b3d !important;
}
a.fc-list-day-text{
  color: #fff;
}
a.fc-list-day-side-text{
  color: #fff;  
}

/*Estilos de actividades*/
.contenedor_botones_actividades{
  margin-top: 50px;
}
.boton_actividad{
  text-align: center;
  box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -webkit-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -moz-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  padding: 10px 2px;
  min-height: 150px;
}

.boton_actividad img{
  max-width: 90%;
  margin: 0 auto;
}

a{
  color: #604b3d;
  text-decoration: none;
}

.boton_actividad_1{
  background-position: 30% 50%;
}

.boton_actividad_2{
  background-position: 80% 50%;
}

.boton_actividad_3{
  background-position: 30% 80%;
}
.boton_actividad_4{
  background-position: 80% 60%;
}

.fondo_actividad_espiritual{
  background-image: url('img/up_circulo_meditacion.svg');
  background-size: 40%;
  background-repeat: no-repeat;
}

.fondo_actividad_fisica{
  background-image: url('img/up_circulo_fisicos.svg');
  background-size: 40%;
  background-repeat: no-repeat;
}

.fondo_actividad_intelectual{
  background-image: url('img/up_circulo_intelectuales.svg');
  background-size: 40%;
  background-repeat: no-repeat;
}

.fondo_actividad_relacional{
  background-image: url('img/up_circulo_relacionales.svg');
  background-size: 40%;
  background-repeat: no-repeat;
}

.fondo_actividad_emocional{
  background-image: url('img/up_circulo_emocionales.svg');
  background-size: 40%;
  background-repeat: no-repeat;
}

.fondo_actividad_perfil_1{
  background-position: 46% 50%;
  background-size: 30px;
}
.fondo_actividad_perfil_2{
  background-position: 60% 50%;
  background-size: 30px;
}
.fondo_actividad_perfil_3{
  background-position: 55% 50%;
  background-size: 30px;
}
.fondo_actividad_perfil_4{
  background-position: 45% 50%;
  background-size: 30px;
}
.fondo_actividad_perfil_5{
  background-position: 60% 50%;
  background-size: 30px;
}

/* Estilos actividades individuales */
.boton_actividad_individual{
  text-align: center;
  box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -webkit-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -moz-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  padding: 10px 2px;
  text-align: center;
  font-size: 18px;
  border-radius: 15px;
  margin-bottom: 15px;
}

/* Estilos de menú radial*/
.bg_espirituales { background: #6a7eff !important; }
.bg_fisicos { background: #3ee1e0 !important; }
.bg_intelectuales { background: #f6d554 !important; }
.bg_relacionales { background: #f49b4c !important; }
.bg_emocionales { background: #fe6656 !important; }

ul.menu_radial {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  position: relative;
  display: block;
  width: 300px;
  height: 300px;
}

ul.menu_radial li {
  position: absolute;
  width: 150px;
  height: 150px;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;;
  overflow: hidden;
  display: block;
  border-bottom: solid 1px #3d3d3d;

}

ul.menu_radial li a {
  color: #fff;
  display: block;
  width: 300px;
  height: 300px;
  border-radius:50%;
  text-align: center;
  background: #fff;
  font-size: 25px;
  border: solid 1px #3d3d3d;
  box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -webkit-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -moz-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
}

ul.menu_radial li:nth-child(1){ transform: rotate(45deg); z-index: 6; }
ul.menu_radial li:nth-child(1) a:hover { background: #6a7eff; }
ul.menu_radial li:nth-child(1) a img { position: absolute; top: 26px; left: 30px; transform: rotate(-45deg); }
ul.menu_radial li:nth-child(2){ transform: rotate(135deg); }
ul.menu_radial li:nth-child(2) a:hover { background: #3ee1e0; }
ul.menu_radial li:nth-child(2) a img { position: absolute; top: 56px; left: 18px; transform: rotate(-136deg); }
ul.menu_radial li:nth-child(3){ transform: rotate(200deg); }
ul.menu_radial li:nth-child(3) a:hover { background: #f6d554; }
ul.menu_radial li:nth-child(3) a img { position: absolute; top: 45px; left: 20px; transform: rotate(-200deg); }
ul.menu_radial li:nth-child(4){ transform: rotate(270deg); }
ul.menu_radial li:nth-child(4) a:hover { background: #f49b4c; }
ul.menu_radial li:nth-child(4) a img { position: absolute; top: 45px; left: 20px; transform: rotate(-270deg); }
ul.menu_radial li:nth-child(5){ transform: rotate(340deg); }
ul.menu_radial li:nth-child(5) a:hover { background: #fe6656; }
ul.menu_radial li:nth-child(5) a img { position: absolute; top: 56px; left: 18px; transform: rotate(-340deg); }

ul.menu_radial li:nth-child(6){ 
  border:solid 1px #3d3d3d;
  z-index: 7;
  background: #e2e1e7;
  position: absolute;
  top: 76px;
  left: 74px;
  border-radius:50%;
  box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -webkit-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
  -moz-box-shadow: 1px 1px 20px 0px rgba(87,87,87,0.4);
}
ul.menu_radial li:nth-child(6) a {
  color: #000;
  font-size: 0.7em;
  background-color: transparent;
  display: block;
  border: none;
  width: 150px;
  height: 150px; }

  ul.menu_radial li:nth-child(6) a img{
    display: block;
    margin: 0 auto;
    padding-top: 20px;
  }

  .info_icono{
    width: 20px;
  }
